import React from 'react';

import '../../css/clients.css';
export default function Clients(){
    return(<div className={`clients_con`}>
        <div className={`clinetTitle`} >
            בין לקוחותינו
        </div>

        <img className={`desk`}src={require(`../../img/home/clients.jpg`)} />
        <img className={`mob`}src={require(`../../img/home/clients_m.jpg`)} />
    
    </div>)
}