import React from "react";
import Form from "./Form/Form.js";

export default function Page({ data }) {
	const link = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3411.2837006149925!2d34.793795084914464!3d31.240568781463125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15026616c48aefb7%3A0xe000ade6fae6578b!2z15nXkNeZ16gg15DXkdeo15TXnSDXqdeY16jXnyAzMSwg15HXkNeoINep15HXog!5e0!3m2!1siw!2sil!4v1600777296484!5m2!1siw!2sil"`;

	return (
		<div className={`pageCon`}>
			<div className={`headCon _${data.path}`}>
				<img src={require(`../../img/page/${data.link}`)} alt='img' />
			</div>

			<div className={`title`}>{data.title}</div>
			{data.path === `contact` ? (
				<>
					<div className={`bodyCon`}>
						<Form className={`page`} />
						<div className={`map`}>
							<iframe
								title='iframe'
								src={link}
								allowfullscreen=''
								aria-hidden='false'
								tabindex='0'></iframe>
						</div>
					</div>
				</>
			) : (
				<>
					<div className={`textCon`}>
						<div className={`textTitle`}>{data.textTitle}</div>
						<div className={`text`}>{data.text}</div>
					</div>

					<div className={`backIcon`}>{data.svg}</div>
					{data.path === `mortgage` && (
						<Form className={`page`} title='פגישת ייעוץ ראשונה ללא עלות!' />
					)}
				</>
			)}

			{data.buttonLink && (
				<a className={`button`} href={data.buttonLink.link}>
					<button>{data.buttonLink.value}</button>
				</a>
			)}
		</div>
	);
}
