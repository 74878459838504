import React from 'react';
import '../../css/homeAbout.css';
import{ ReactComponent as  Logo } from '../../svg/home/serv.svg';

export default function About(){

    return(
        <div className="aboutCon">
            <div className={`aboutTitle`} >
                אודותינו
            </div>
            <div className={`homeAbout`} >   
            <section>
                    <div className={`textCon`}>
                                "מורנו סוכנות לביטוח בע"מ" הוקמה בשנת 1986 ע"י מורנו מרדכי רס"ן <br />
                            במיל' חבר לשכת סוכני הביטוח בישראל, בעל רישיונות בכל<br />
                            ענפי הביטוח ומאז ועד היום הינה סוכנות בכירה בחברות הביטוח שאיתן היא עובדת."
                    </div>
                    <a href="./about">
                        <button>קרא עוד {`>`}</button>
                    </a>

            </section>

            <section>
            <Logo />
            
            <div className={`title`}>
                אמנת השירות
            </div>

            <a href={require('../../pdf/moreno.pdf')}>
                <button> {`לקריאה >`}</button>
            </a>
            
            </section>
            </div>
        </div>

    );
}