import React from 'react';
import Form from './Form/Form.js';

export default function Header(){
    const url = 'https://bc-adv.co.il/campaign/';
    const link = 'https://www.passportcard.co.il/Purchase?AffiliateId=S9l5%2Fnlpo0OuFBrq4flyBQ%3D%3D';
    
    return(
        <>
        <div className={`header_img`}>
            <img className={`desk`} src={require(`../../img/home/headBg.jpg`)} />
            <img className={`mob`} src={require(`../../img/home/headBgm.jpg`)} />
        </div>
        <Form className={`float`} url={url} />
        <div className={`header_sec`}>
            <img className={`desk`} src={require(`../../img/home/card.jpg`)} />
            <img className={`mob cardImg`} src={require(`../../img/home/card_m.jpg`)} />

            <div className={`con`}>
                <div className={`text_con`}>
                        <div className="text">
                        {`ביטוח
                        נסיעות לחול `}
                        </div>
                        <a href={link}>
                            <button>לרכישה {`>`}</button>
                        </a>
                </div>
            </div>

        </div>
        </>
)
}