import React from 'react';
import{ ReactComponent as  Icon1 } from '../../svg/icons/1.svg';
import{ ReactComponent as  Icon2 } from '../../svg/icons/2.svg';
import{ ReactComponent as  Icon3 } from '../../svg/icons/3.svg';
import{ ReactComponent as  Icon4 } from '../../svg/icons/4.svg';
import{ ReactComponent as  Icon5 } from '../../svg/icons/5.svg';
import{ ReactComponent as  Icon6 } from '../../svg/icons/6.svg';
import{ ReactComponent as  Icon7 } from '../../svg/icons/7.svg';
import{ ReactComponent as  Icon8 } from '../../svg/icons/8.svg';


import '../../css/home_nav.css';

export default function homeNav(){

    let data = [
        {
            icon : <Icon1 />,
            name : `ביטוח 
            נסיעות לח"ל`,
            link: './travel-abroad',
        },
        {
            icon : <Icon2 />,
            name : `איתור
             כספים אבודים`,
            link: './lost-funds',
        },
        {
            icon : <Icon3 />,
            name : `ביטוח 
            בריאות`,
            link: './health-insurance',
        },
        {
            icon : <Icon4 />,
            name : `פיננסים 
            גמל השתלמות`,
            link: './finance-provident-training',
        },
        {
            icon : <Icon5 />,
            name : `ביטוח
             עסקים`,
            link: './business-insurance',
        },
        {
            icon : <Icon6 />,
            name : `ביטוח
             רכב`,
            link: './car-insurance',
        },
        {
            icon : <Icon7 />,
            name : `ביטוח 
            דירה`,
            link: './home-insurance',
        },
        {
            icon : <Icon8 />,
            name : `חיים
             פנסיה וחיסכון`,
            link: './financia-life',
        },
    ];

    return (
        <div className={`home_Nav`}>
            <div className={`itemsCon`}>
            <div className={`borderMidlle`} ></div>
            {data.map(item=>{
                return (
                    <div className={`item`}>
                        <div className={`icon`}>
                            {item.icon}
                        </div>
                        <div className={`name`}>
                            {item.name}
                        </div>
                        <a href ={item.link} >
                            <button>קרא עוד {`>`}</button>
                        </a>
                    </div>
                )
            })}
            </div>
        </div>


    );
}