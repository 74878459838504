import React from 'react';
import {BrowserRouter as Router , Switch , Route} from 'react-router-dom';
import {BrowserRouter as    IndexRoute} from 'react-router-dom';

import './css/app.css';

import Nav from './component/Nav/nav.js';
import Home from './component/Home/Home.js';
import Page from './component/Page/Page.js';
import Sale from './component/Page/Sale.js';

import Footer from './component/Footer.js';
import Credit from './component/Credit.js';

import './css/page.css';


import {Data} from './Data/Data.js';

function App() {
     
  
  return (
    <div className="morano">
      <Router >
            <Nav />
            <main>
              <Switch>
                {/* <Route path="/" exact component={()=> <Home />}/> */}
                <Route path="/" exact component={()=> <Home />}/>
                <Route path={`/sale`} exact  component={()=> <Sale />} />
                {Data.map(page =>{
                  return <Route path={`/${page.path}`}  component={()=> <Page  data={page}/>} />
                })}

              </Switch> 
            </main>

          <Footer />
          <Credit />
      </Router>


    </div>
  );
}

export default App;
