import React, { useState } from 'react';
import '../../../css/massageCon.css'

export default function popUpAlert(props) {
    console.log(props);

        return ( 
        <div className="thanks" id="101">
            <div className="bg"  onClick={props.func} ></div>

            <div className="massgaeCon" >
                <div className="imgCon">
                </div>
                <div className="closeMsg" onClick={props.func}> ✖ </div>   
                    <div className="textCon">

                        <div className="imgCon">
                        </div>

                        <div className="text">
                            <div className={`title`} >
                                תודה!, <br />
                            </div>
                            פניתך התקבלה במערכת, <br />
                            נחזור אליך בהקדם. 
                        </div>

                        <div className="star">
                        </div>
                     </div>
    </div>
            
</div>)

}