import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../css/from.css";
import { ReactComponent as Checkmarck } from "../../../svg/form/checkmarck.svg";
import { ReactComponent as Trianagle } from "../../../svg/form/triangle.svg";
import { ReactComponent as Logo } from "../../../svg/form/logo-12.svg";
import MassgaeCon from "./MassageCon.js";
import MassageError from "./MassageError.js";
import { useLocation } from "react-router-dom";

export default function Form(props) {

	const { pathname } = useLocation();

	const [form, setForm] = useState({
		fullName: "",
		phone: "",
		email: "",
		ads: true,
		select: "",
		pathname 
	});
	const [phoneVaild, setPhoneVaild] = useState(false);
	const [msg, setMsg] = useState("");
	const [send, setSend] = useState(false);
	const [rec, setRec] = useState(false);
	const [url, setUrl] = useState("");
	const [className, setClassName] = useState("");
	const [adsId, setAdsId] = useState("");

	let location = useLocation();

	console.log(location);

	useEffect(() => {
		setUrl("");
		setClassName(props.className);
		const min = 1;
		const max = 100;
		setAdsId(min + Math.random() * (max - min));
	}, []);

	const phonehandle = (e) => {
		if (
			e.charCode == 8 ||
			e.charCode == 0 ||
			e.charCode == 13 ||
			(e.charCode >= 48 && e.charCode <= 57)
		) {
			setPhoneVaild(true);
		} else {
			setPhoneVaild(false);
		}
	};

	const formValidation = (e) => {
		e.preventDefault();
		var state = false;

		//check name
		var x = form.fullName.split(" ");
		if (x.length < 2 || form.fullName == "" || x[1] == "" || x[0] == "") {
			setMsg("אנא הזן שם מלא");
			state = false;
		} else {
			setMsg("");
			state = true;
		}

		if (state) {
			//check phone number
			state = false;
			let vailedPhone = /^\d+$/.test(form.phone);
			if (vailedPhone) {
				let tempPhone = String(form.phone).split("");
				if (
					tempPhone[0] != "0" ||
					(tempPhone[1] != "5" && tempPhone[1] != "7") ||
					tempPhone.length <= 9 ||
					tempPhone.length > 10
				) {
					vailedPhone = false;
				}
			}
			if (!vailedPhone) {
				setMsg("אנא הזן מספר טלפון תקין");
			} else {
				setMsg("");
				state = true;
			}
		}

		if (state) {
			//check email
			let filter_email =
				/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z0-9])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i;
			filter_email = filter_email.test(form.email);
			if (!filter_email || form.email == "") {
				setMsg("אנא הזן אימייל תקין");
				state = false;
			} else {
				setMsg("");
				state = true;
			}
		}

		if (state) {
			setSend(true);
			//upload the leads
			const url_2 = "https://www.moreno-ins.co.il/api/postLead.php";
			axios
				.post(url_2, {
					form,
					headers: {
						"Content-type": "application/json",
					},
				})
				.then((res) => {
					if (res.data[0]) {
						setRec(true);
					} else {
						setRec(false);
						if (res.data[1] != "") {
							setMsg(res.data[1]);
						}
					}
					setSend(false);
				})
				.catch((res) => {
					setSend(false);
					setRec(false);
					alert(res);
				});
		} else {
			setRec(false);
			setSend(false);
		}
	};

	const clearMsg = () => {
		setMsg("");
	};
	const doneReg = () => {
		setMsg("");
		setRec(false);
		setForm({ fullName: "", phone: "", email: "", ads: true });
	};

	const SelectData = [
		{ val: `נסיעות לחו"ל` },
		{ val: `רכב` },
		{ val: `דירה` },
		{ val: `עסקים` },
		{ val: `חיים פנסיה וחיסכון` },
		{ val: `בריאות` },
		{ val: `גמל והשתלמות` },
		{ val: `איתור כספים` },
		{ val: `משכנתאות` },
	];

	return (
		<>
			<div className={`formCon ${className}`}>
				<img src={require(`../../../img/home/formBg.jpg`)} alt='image_' />

				<form className={`form`} onSubmit={formValidation}>
					<div className={`title`}> {`רוצים לשמוע עוד?`} </div>
					<div className={`text`}> מלאו פרטים ונציגנו יחזרו אליכם בהקדם!</div>

					<div className={`inputsCont`}>
						<input
							type='text'
							value={form.fullName}
							onChange={(e) => setForm({ ...form, fullName: e.target.value })}
							placeholder={`שם מלא`}
						/>

						<input
							type='tel'
							value={form.phone}
							onKeyPress={phonehandle}
							onChange={(e) => {
								if (phoneVaild) setForm({ ...form, phone: e.target.value });
							}}
							placeholder={`טלפון`}
						/>

						<input
							type='email'
							value={form.email}
							onChange={(e) => setForm({ ...form, email: e.target.value })}
							placeholder={`דו"אל `}
						/>

						<div className='selectCon'>
							<Trianagle />
							<select
								value={form.select}
								onChange={(e) => setForm({ ...form, select: e.target.value })}>
								<option selected disabled value=''>
									{" "}
									ביטוח מבוקש
								</option>
								{SelectData.map((item) => {
									return <option value={item.val}>{item.val}</option>;
								})}
							</select>
						</div>

						<button>{!send ? "אישור" : "שולח..."}</button>
					</div>
					<div className={`pirsumdiv`}>
						<input
							id={`checkbox${adsId}`}
							type='checkbox'
							checked={form.ads}
							onChange={(e) => {
								setForm({ ...form, ads: e.target.checked });
							}}
						/>
						<label htmlFor={`checkbox${adsId}`}>אני מאשר מידע פירסומי </label>
					</div>

					<Logo />
				</form>
			</div>

			{!send && msg == "" && rec ? (
				<div className='endReg'>
					<MassgaeCon func={doneReg} />
				</div>
			) : !send && msg != "" && !rec ? (
				<MassageError data={{ func: clearMsg, msg: msg }} />
			) : (
				""
			)}
		</>
	);
}
