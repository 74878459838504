import React , {useEffect} from 'react';
import {Link} from 'react-scroll'


export default function ScrollToForm({className}){

    useEffect(() => {
        window.addEventListener("scroll" ,()=>{
            let scroll = document.querySelector('.scrollCon');
            let scrollBar = document.documentElement.scrollTop
            var body = document.body,
            html = document.documentElement;
        
        var height = Math.max( body.scrollHeight, body.offsetHeight, 
                               html.clientHeight, html.scrollHeight, html.offsetHeight );
            let pageDown = (document.querySelector('.static').offsetHeight + document.querySelector('.Copyright').offsetHeight) ;
            if((height - pageDown * 2) <= scrollBar){
                scroll.style.display = 'none';
            }else{
                scroll.style.display = 'flex';
            }
        })

        return () => {
            
        }
    }, [])

    return (
        <Link className="scrollCon mob" to={className} smooth={true} duration={500}>
                ליצירת קשר לחץ כאן {'>'}
        </Link>
        )

}