import React from 'react'
import Form from '../Home/Form/Form.js'

import '../../css/sale.css';

function Sale() {
    return (
        <div className={`sale_con`}>
            <div className={`img_con`}>
                <img className={`mob`} src={require('../../img/page/sale_mob.jpg')} />
                <img className={`desk`} src={require('../../img/page/sale_desk.jpg')} />
            </div>
            <div className={`title`}>
                        
            </div>
            <div className={`body_con`}>

                <div className={`img_con`}>
                    <img src={require('../../img/page/sale_1.png')} />
                </div>

                <Form className={`static sale`} />
            </div>



        </div>
    )
}

export default Sale
