import React, { useState } from 'react';
import '../../../css/massageError.css'


export default function MassageError(props){
    console.log(props);
    const func = props.data.func;
    const msg = props.data.msg;

return(
<div className="popUpAlert">
    <div className="bg" onClick={func}> </div>
    <div className="whiteBlock">
        <div className="closeError" onClick={func}> ✖ </div>
        <div className="title">
            שגיאה!
        </div>
        <div className="text">
            {msg}
        </div>
    </div>
</div>
 );


}