import React from 'react';

import '../../css/home.css'

import Header from './Header.js';
import About from './About.js';
import Nav from './Nav.js';
import Clients from './Clients.js';
import Form from './Form/Form.js';
import ScrollToForm from './ScrollToForm.js';

export default function Home(){
return(
    <>
    <Header />
    <About />
    <Nav />
    <Clients />
    <Form className={`static`} />
    <ScrollToForm className={`static`} />
    </>

);

}