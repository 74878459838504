import React from "react";
/*SVG files*/
import { ReactComponent as Icon1 } from "../svg/icons/1.svg";
import { ReactComponent as Icon2 } from "../svg/icons/2.svg";
import { ReactComponent as Icon3 } from "../svg/icons/3.svg";
import { ReactComponent as Icon4 } from "../svg/icons/4.svg";
import { ReactComponent as Icon5 } from "../svg/icons/5.svg";
import { ReactComponent as Icon6 } from "../svg/icons/6.svg";
import { ReactComponent as Icon7 } from "../svg/icons/7.svg";
import { ReactComponent as Icon8 } from "../svg/icons/8.svg";
import { ReactComponent as Icon9 } from "../svg/icons/9.svg";
import { ReactComponent as Icon10 } from "../svg/icons/10.svg";
import { ReactComponent as Icon11 } from "../svg/icons/11.svg";
import { ReactComponent as Icon12 } from "../svg/icons/12.svg";
export const Data = [
	{
		title: "אודותינו",
		path: "about",
		svg: <Icon9 />,
		link: "financeprovidenttraining.jpg",
		textTitle: `
      "מורנו סוכנות" לביטוח בע"מ" הוקמה בשנת 1986 ע"י מורנו מרדכי רס"ן במיל' חבר לשכת סוכני הביטוח בישראל, בעל רישיונות בכל ענפי הביטוח ומאז
       ועד היום הינה סוכנות בכירה בחברות הביטוח שאיתן היא עובדת."
      `,
		text: `מורנו סוכנות לביטוח" שומרת על המסורת ובמשך 10 שנים רצופות הסוכנות שומרת על מקום של כבוד בצמרת סוכנויות הטיטניום של חברות הביטוח.

      "מורנו סוכנות לביטוח בע"מ" נמנית עם סוכנויות הביטוח המובילות ומעניקה שירותי ביטוח, פנסיה,פיננסים לאלפי לקוחות עסקיים ופרטיים תוך הקפדה על רמת שירות גבוהה ומקצועית. "מורנו סוכנות לביטוח" הינה תאגיד ביטוח בעל רישיונות בכל ענפי הביטוח: כללי, פנסיוני וימי הסוכנות מאמינה שבזכות פעילותה בכל ענפי הביטוח ובזכות בכירותה בכל חברות הביטוח עמה עובדת הסוכנות הינה יכולה לתת למבוטח פתרונות רבים תחת קורת גג אחת ותוך מציאת הכיסוי המתאים ביותר למבוטח ובמחיר הנמוך ביותר לאותו כיסוי.

      ל"מורנו סוכנות לביטוח" קיים יתרון נוסף על רוב סוכנויות הביטוח בישראל היות וקיים בסוכנות דור המשך, משה מורנו חבר לשכת סוכני הביטוח בישראל, בעל רישיונות בכל ענפי הביטוח, מכיוון שהמשכיות היא אחד התנאים הבסיסיים להמשך הטיפול הפנסיוני של הלקוח בעתיד.

      "מורנו סוכנות לביטוח" מציעה לכם פתרונות ב: ביטוח רכב, דירה, בריאות, חיים, נסיעות לחו"ל, ביטוח עסק, תאונות אישיות, קופות גמל, קרנות השתלמות, פנסיה, ביטוח מנהלים וחיסכון, אובדן כושר עבודה, קופות פיצויים , הלוואות, ניהול תיקים מקצועי וחסכונות מושקעים, ביטוח עובדים זרים, מחלה, משכנתאות וביטוחי משכנתאות, ביטוח ימי ואווירי, קבלנים צמ"ה וחבויות.
      לקוחות הסוכנות הינם מהמגזר העסקי והפרטי. מבין לקוחותיה מהמגזר העסקי נמנות החברות הגדולות במשק מענף הבניה, ענף המתכות, קבלנים, רשויות מקומיות ושלל עסקים קטנים וגדולים כאחד.
      כל אלו ועוד מציבים אותנו בצמרת סוכנויות הביטוח בישראל.

      "מורנו סוכנות לביטוח (2005 ) בע"מ"
`,
	},

	{
		title: 'נסיעות לחו"ל',
		path: "travel-abroad",
		svg: <Icon1 />,
		link: "travelabroad.jpg",

		textTitle: `תוכנית ביטוח הנסיעות שלנו מכסה
      את כל הצרכים שלכם בחו"ל: `,

		text: `כרטיס PassportCard לתשלום במקום של הוצאות רפואיות בחו"ל.
      פטור מהשתתפות עצמית, בכל השירותים הרפואיים עליהם תשלמו באמצעות הכרטיס.
      
      הטסה רפואית לישראל, בליווי צוות וציוד רפואי. 
      פינוי אווירי ממקום האירוע. 
      גבול אחריות גבוה במיוחד - 5,000,000$
      
      כיסוי במקרה של רעידת אדמה.
      מוקד חירום וסיוע למבוטח ולבני משפחתו - 24 שעות ביממה. 
      חדר רופאים טלפוני בעברית - 24 שעות ביממה. 
      
      התאמה אישית של התכנית, באמצעות הרחבות בתשלום:
      כל סוגי הטיולים - כולל טיולי תרמילאים, ספורט אתגרי וסקי.  
      כל מצב רפואי – כגון, הריון והחמרה של מצב רפואי קיים.`,
		buttonLink: {
			link: `https://www.passportcard.co.il/Purchase?AffiliateId=S9l5%2Fnlpo0OuFBrq4flyBQ%3D%3D`,
			value: `לרכישת ביטוח >`,
		},
	},

	{
		title: "ביטוח רכב",
		path: "car-insurance",
		svg: <Icon6 />,
		link: "carinsurance.jpg",
		textTitle: `המציאות בישראל מלמדת אותנו שביטוח החובה לרכב אינו מספיק ומשתלם מאוד לבטח את הרכב מהיבטים נוספים על מנת שלא נצטרך להוציא סכומים גבוהים במקרים שונים כגון פגיעה ברכב שלנו או פגיעה ברכב אחר, גניבות ועוד. 
      היו זהירים בכביש!
       `,
		text: `
      ביטוח חובה: נקבע בחוק ומכסה נזקי גוף. זהו הביטוח הבסיסי ביותר.

      ביטוח צד ג': מכסה נזקים לרכוש של צד שלישי כתוצאה מנהיגה ברכב.

      ביטוח מקיף: הביטוח הכדאי והנוח ביותר עבורכם במקרה הצורך. זהו ביטוח צד ג' עם הרחבות חשובות כגון סיכוני אש, גניבות ופריצות לרכב, רכב חלופי, גרירה, שמשות.

      ביטוח רכב במסלולים מיוחדים:
      ביטוח רכב מעל 4 טון: מכסה נזק לרכב עצמו או נזק שייגרם לרכב ו /או לרכוש של צד שלישי כתוצאה משימוש ברכב מסחרי כגון משאיות, אוטובוסים, מלגזות, רכבים חקלאיים למינהם, רכב לטאטוא כבישים, אמבולנסים ועוד, שמשקלם מעל 4 טון.

      ביטוח צמ"ה: ביטוח שהינו חשוב ביותר בתחום התשתיות והבניה. הביטוח מיועד לציוד מכני הנדסי הכולל טרקטורים, מנופים, עגורנים, מחפרים, משאבות, מערבלי בטון וכד'. הכיסויים העיקריים אותם ניתן לרכוש הם כיסויים לנזקי טבע הפוגעים בצמ"ה וכן כיסוי מפני גניבה, פריצה ונזק בזדון.

      `,
	},

	{
		title: "ביטוח דירה",
		path: "home-insurance",
		svg: <Icon7 />,
		link: "homeinsurance.jpg",
		textTitle: `לאחר שביצעתם את אחת הרכישות המשמעותיות ביותר בחייכם שהיא רכישת בית, עליכם להבטיח שרכישה זו לא תרד לטימיון מסיבות שונות כמו נזקי טבע ורעידת אדמה, שריפה, הצפה, גניבה וכד'. אמנם מקרים כאלו אינם קורים בכל יום וייתכן ולמזלכם אף לא יקרו כלל לביתכם אבל במידה והוא יינזק, עליכם להבטיח את עצמכם מראש ולדאוג לכיסוי הולם ולפיצויים הרצויים.      `,
		text: `ביטוח דירה: ביטוח של המבנה עצמו כולל החלק היחסי ברכוש המשותף (בבניין משותף) וביטוח תכולתו לרבות תכשיטים ודברי ערך נוספים הנרשמים בפוליסה.

      ניתן גם כאן להרחיב ולערוך ביטוח צד שלישי וכלפי עובדי משק הבית.
      ביטוח משכנתא: מייצג שני ביטוחים שונים בסוגם: האחד- ביטוח חיים למשכנתא שנועד להתמודד עם מצב שבו אחד הלווים נפטר והביטוח מגן מבחינה כלכלית על בן/ בת הזוג הנותר/ת. הסוג השני הוא ביטוח המבנה המכסה את רוב מקרי הביטוח אך לא את כולן.
      
      ביטוח דירה להשכיר: כיום בעלי דירות להשכרה יכולים לקבל מענה לטרחה הכרוכה בניהול השוטף של ההשכרה וכך יכולים ליהנות מפירותיה בנחת.
      
      חבילת הביטוח כוללת:
      א. ביטוח מקיף למקרה של שריפה, נזקי טבע וכו'.
      
      ב. ביטוח מקיף לפריטי התכולה השייכים לבעל הבית כגון מזגנים, מקרר, תנור בישול ואפייה וכד', הכל כמפורט בחוזה השכירות ועד לערך מרבי של 25,000 ₪.
      
      ג. ביטוח חבות כלפי צד ג' ( כצד שלישי ישמשו שוכרי הדירה).
      
      ד. חבילת שירותי תיקונים במבנה ובמערכות רכוש בעל הדירה.
      בתים משותפים: כל בניין מגורים, ישן או חדש, חשוף לנזקים לרכוש המשותף שהינו מעליות, חדרי מדרגות, חדרי שירותים שונים, חניות, מתקנים בגינה וכד'. הביטוח הוא של כל מבנה הבית המשותף על חלקיו המשותפים. הביטוח מכסה מפני נזקי אש, התפוצצות, נזקי צנרת, שיטפון, רעידת אדמה.
      
      בנה ביתך: בניית בית היא עסק יקר ומורכב ולשם מענה על הצרכים קיים ביטוח קבלנים "בנה ביתך" המותאם להיקף ולעלות הבניה, יתרון המוזיל את הפרמיה באופן משמעותי. החבילה כוללת כיסוי עבור שווי הפרויקט, כיסוי במקרה של נזק למתקנים וציוד עזר, נזק למבני עזר, פינוי הריסות, נזק לרכוש סמוך, נזק לרכוש הפרויקט, נזק שנגרם כתוצאה מתכנון לקוי, כיסוי שכר אדריכלים ומהנדסים, כיסוי לפריצות, גניבות, אחריות כלפי צד שלישי, עובדים, פרעות/ שביתות, רעידת אדמה ונזקי טבע.
      
      אנחנו "במורנו סוכנות לביטוח" נבנה ביחד איתך תוכנית ביטוח "בנה ביתך" שתעניק לך הגנה מקיפה לאורך הבנייה
            `,
	},

	{
		title: "ביטוח עסקים",
		path: "business-insurance",
		svg: <Icon5 />,
		link: "businessinsurance.jpg",
		textTitle: `כל בעל עסק מכיר בחשיבותו של ביטוח לעסק שלו ובכך שזוהי הוצאה בלתי נפרדת כשאר ההוצאות. כבעל עסק המשרת לקוחות, מחזיק ברכוש ומעסיק עובדים מוטלת אחריות רבה על הכתפיים ותמיד קיימים סיכונים אליהם העסק חשוף אשר עלולים לשבש לחלוטין את פעילות החברה.  "מורנו סוכנות לביטוח" דואגת להתאים ללקוחותיה את הפוליסה לה הם זקוקים, על פי צרכיהם ואופי העסק שהם מנהלים.
      `,
		text: `ביטוח עסקים: במסגרת ביטוחי העסק מוצעים לך ביטוח רכוש ואובדן רווחים / הכנסה, ביטוח המבנה ותכולת העסק, ביטוח שבר מכני,  ציוד אלקטרוני, ביטוח רכוש בהעברה, ביטוח כספים ועוד. 

      ביטוח משרדים וחנויות: קיימת פוליסה מיוחדת לביטוח המשרד הכוללת כיסוי לרכוש המשרד מנזקי אש, פריצה ורעידת אדמה, כיסוי לנזקי טרור, ביטוח צ'קים ומזומנים במקרים של גניבה או שוד, ביטוח ציוד אלקטרוני, הגנה מפני פריצה או ריגול ושחזור מידע ומאגרי נתונים, המכילים את רוב המידע של העסק.
      
      ביטוח בתי מלאכה: בתי חרושת, מוסכים, מסגריות, נגריות וכד' חשופים באופן משמעותי לסיכונים רבים כגון פריצות, שריפות, תאונות ועוד. לכל אלה ניתן ביטוח הנותן מענה והגנה מקיפה לכל הנזקים העלולים להיגרם בבית המלאכה.
      
      ביטוח חבויות: כולל בתוכו פרקים שונים שנועדו להגן על בעלי מקצוע בתפקיד בכיר שמתוקף אחריותם עלולים להתמודד עם תביעות מסוגים שונים שיוגשו נגדם בשל טעות או רשלנות מקצועית למשל. סוגי הביטוחים הינם- ביטוח אחריות המוצר, אחריות מעבידים, אחריות מקצועית ואחריות כלפי צד שלישי.
      
      ביטוח קבלנים: ביטוח כל סוגי הפרויקטים הקבלניים, פשוטים ומורכבים. קיימת חלוקה לביטוח עבודות קבלניות של תשתיות ובנייה (הקמת גשרים, בניינים 
      וכבישים) וביטוח עבודות בהקמה של ציוד לרבות ציוד תעשייתי. הכיסוי הוא על בסיס כל הסיכונים.`,
	},

	{
		title: "חיים פנסיה וחיסכון",
		path: "financia-life",
		svg: <Icon8 />,
		link: "financialife.jpg",
		textTitle: `כל משפחה מתפרנסת בזכות עבודתם של ההורים ובד"כ
      זהו מקור הפרנסה העיקרי. `,

		text: `למה צריך ביטוח חיים?
      במידה ואדם לא הגיע לשיבה טובה הוא משאיר אחריו משפחה ואם ביטח את עצמו בביטוח חיים, משפחתו תוכל להמשיך להתקיים כרגיל מבחינה כלכלית ולא תיפגע מהעובדה שנפטר ולא יוכל להמשיך לפרנס אותם. זהו ביטוח מאוד חשוב למעשה ומומלץ לכל אחד או אחת שתומכים בבני משפחה.
       
      ביטוח חיים למקרה מוות:
      קיימות מספר פוליסות שמבטיחות הכנסה במקרה של מוות המבוטח. ניתן לבחור לקבל את הסכום הכספי בצורת משכורת חודשית או כסכום חד פעמי, ניתן לבטח מקרה מוות כתוצאה מתאונה בלבד, ניתן לרכוש ביטוח חיים לסילוק הלוואה במקרה מוות וביטוח חיים המותאם למי שאינו בריא ואינו עומד בדרישות הבריאות הסטנדרטיות.
       
      ביטוח למקרה אובדן כושר עבודה:
      במקרה של אובדן היכולת לעבוד, גם כאן נפגעת רמת החיים בצורה משמעותית מעבר לנזק הנפשי. על מנת להבטיח את הכנסתך, רכוש ביטוח אובדן כושר עבודה שנגרם כתוצאה ממחלה או מתאונה והשב לך שקט נפשי בתחום הכלכלי.
      
      ביטוח למקרה נכות:
      אם רכשת ביטוח חיים, תוכל להרחיבו  למקרה של נכות. כלומר בעודך בחיים, אם חלילה תהפוך לנכה כתוצאה ממחלה או מתאונה ותאבד את היכולת לעבוד, תוכל להפעיל את ביטוח החיים שרכשת ולקבל סכום חד פעמי כפיצוי!
    
      ביטוח מנהלים:
      חשיבה על פרנסה לאחר הפרישה לגמלאות היא חשיבה נכונה 
      ומוצדקת כבר בגיל צעיר ומוקדם מאוד. ביטוח מנהלים לשכירים במקום עבודתם מבטיח את זכויותיהם הפנסיוניות ומספק מענה מושלם ועדכני לכל מי שדואג לגיל הפרישה ואף למקרה מוות או נכות.
      
      פנסיה וחיסכון:
      את החיסכון לטווח הארוך וההפרשה לטובת הפנסיה צריך לתכנן ביחד עם מומחים וכבר מגיל צעיר, משנות העשרים לחיים. ככל שתתחילו לחסוך בגיל  מוקדם יותר כך תוכלו לצבור סכומי כסף גבוהים יותר שישרתו אתכם לתקופה שבה תצאו לפנסיה.  "מורנו סוכנות לביטוח" תעמיד לרשותכם את המומחים בתחום הפנסיה והחיסכון על מנת שתהיו שותפים בכל הנוגע לפנסיה שלכם.
       
      פנסיה: 
      אדם המפריש לאורך השנים סכומי כסף שונים במסגרת עבודתו או באופן עצמאי, לטובת קרנות פנסיה בצורה מתוכננת היטב מבטיח לעצמו שמירה על רמת חיים זהה כשיפרוש לגמלאות ויפסיק לעבוד ולקבל משכורת.
    
      חיסכון באמצעות פוליסה:
      חיסכון המאפשר לך לחסוך ולהשקיע כספים כהפקדה שוטפת או כהפקדה חד פעמית לכל מטרה, את החיסכון תוכל לייעד עבור ילדיך או נכדיך למטרות שונות. כגון: מימון לימודים, חתונה, רכישת נכס, נדל"ן וכו'.
      פנה אלינו להתאמת מוצר המתאים לצרכיך.

      חיסכון פיננסי :
       מוצר פיננסי חדשני וייחודי ניהול כספי חיסכון החל מ- 50,000 ₪  בעל  יתרונות מרובים כמו גמישות והתאמה אישית, יתרונות מיסוי, חיסכון בעלויות ועוד. `,
	},

	{
		title: "ביטוח בריאות",
		path: "health-insurance",
		svg: <Icon3 />,
		link: "healthinsurance.jpg",
		textTitle: `
      ביטוח בריאות פרטי \n
      מעניק לכם את פתרונות הרפואה המתקדמים בעולם בזמינות מיידית
      `,

		text: `
    עם ביטוח בריאות פרטי, חלילה בקרות מקרה תוכלו להיות בטוחים שאתם והמשפחה שלכם תקבלו את כל מה שיש לרפואה היום להציע.

   - תרופות מחוץ לסל הבריאות
   - השתלות איברים
   - ניתוחים פרטיים בארץ ובחו"ל
   - טיפולים מחליפי ניתוח
   - ייעוצים אצל רופאים מומחים
   - בדיקות אבחנתיות
   - בריאות והתפתחות הילד
   - הגנה כלכלית במקרה של אירוע רפואי חמור

אנו במורנו סוכנות לביטוח נוכל להתאים עבורכם את התוכנית המתאימה והמשתלמת ביותר ולהיות שם עבורכם בכל רגע בחיים
    `,
	},

	{
		title: `מבצעים`,
		path: `sale`,
		svg: <Icon11 />,
	},

	{
		title: `צור קשר`,
		path: "contact",
		svg: <Icon10 />,
		link: "contact.jpg",
	},

	{
		title: "פיננסים גמל השתלמות",
		path: "finance-provident-training",
		svg: <Icon4 />,
		link: "financeprovidenttraining.jpg",
		textTitle: `קבוצת מכשירי חיסכון פנסיוניים לטווח בינוני או ארוך. קופות הגמל השונות מעניקות זיכוי וניכוי במס לחוסכים. אלו יכולים להיות עצמאיים או עמיתים שכירים שתשלומיהם לקופת הגמל מבוצעים בידי
      מעסיקיהם.`,
		text: `סוגי קופות
      קופת תגמולים: קופת גמל גמישה, הפועלת באמצעות השקעה באג"ח ממשלתיות וקונצרניות בדירוג גבוה ובנוסף באמצעות חשיפה לנכסים בארץ ובחו"ל ומרכיב מנייתי בהתאם לתנאי השוק.  מטרת הקופה היא להשיג לעמיתיה תשואה עודפת מעל הממוצע בשוק.
      
      קופה מסלולית: קופת גמל רבת מסלולי השקעה ודינאמית, המותאמת אישית לצרכי העמית ולרמת הסיכון הרצויה לו תוך כדי התאמה לתנודות המתרחשות בשוק ההון. עיקרי הקופה הם: מסלול כללי, מסלול צמוד מדד, מסלול שקלי קצר טווח ומסלול מנייתי. ניתן לעבור במהירות ובקלות בין המסלולים.
      
      קרנות השתלמות
      תוכנית חיסכון לטווח בינוני המשתלמת ביותר בתחומה ומיועדת לשכירים, עצמאיים ולחברי קיבוצים ומושבים שיתופיים. במקור נועדה לשמש את העובדים למימון השתלמויות אולם כיום היא משמשת בעיקר כאפיק חיסכון כללי לטווח הבינוני 
      `,
	},

	{
		title: "משכנתאות",
		path: "mortgage",
		svg: <Icon4 />,
		link: "mortgage.jpeg",
		textTitle: `משכנתאות`,
		text: `ליווי מלא לאורך ההליך כולל במעמד החתימה בבנק , רישומים ועד קבלת הכסף

				חסכון ממוצע של מעל 120,000 ₪
				הפחתת התשלומים על הלוואות נוספות
				גיוס כספים לכל מטרה
				
				איחוד הלוואות
				
				מחזור משכנתאות
				חדש - משכנתאות והלוואות דרך חברות הביטוח
				
				`,
	},

	{
		title: "איתור כספים אבודים",
		path: "lost-funds",
		svg: <Icon2 />,
		link: "lostFunds.jpg",
		textTitle: `איתור כספים אבודים הפך בשנים האחרונות לאחד הנושאים הפופולריים ביותר.
      יותר ויותר אנשים מתחילים לקחת אחריות על ההתנהלות הפיננסית שלהם. `,

		text: `אם בעבר רוב האנשים לא היו מודעים בכלל לקופות הגמל השונות שנפתחו עבורם או לכל ההתרחשויות בקופות הפנסיה שלהם, המצב היום הוא שונה. לאט – לאט המודעות של האנשים לנושאים אלו הולכת ועולה, ורוב האנשים כבר מתחילים לשאול שאלות ולבדוק מה קורה עם הכספים שלהם. כל אחד החליף במהלך חייו לפחות כמה מקומות עבודה, דבר שגרר פתיחה של מספר קרנות פנסיה, קרנות השתלמות וכדומה. רוב העובדים שכחו כבר מזמן לגבי הקופות השונות שנפתחו עבורם, דבר שגרם ללא מעט כספים אבודים. עכשיו זה בדיוק הזמן לאתר את הכספים האלו ולקחת עליהם את האחריות המתאימה. כל מה שחשוב לדעת לגבי איתור כספים אבודים.

      כאמור, לכל אחד בארץ קיימת לפחות קרן פנסיה או קופת גמל אחת שהוא כלל אינו מודע לקיומה. במהלך השנים לא מעט כספים שסגורים בקרנות ובקופות השונות הולכים לאיבוד, ואף אחד אינו יודע כיצד הם מנוהלים ומה גובה דמי הניהול שנגבים מהם. לכן חשוב לאתר את כל הכספים האלו ולהתחיל לעשות סדר בבלגן. בצורה כזו ניתן לאתר גם חשבונות בנק ישנים שכבר מזמן נשכחו, לאתר כספים שהיו שייכים לקרובי משפחה שנפטרו וכדומה. ניתן להיעזר באתר של משרד האוצר על מנת לגלות אלו כספים אבודים קיימים על שמכם, אך על מנת לגלות פרטים נוספים יש צורך להיעזר באיש מקצוע.
      
      `,
		buttonLink: {
			link: `https://polywizz.com/#/sn?g=b0735fcf-10c0-4482-a4bc-8da8e0eed2d2`,
			value: `לבדיקת תיק ביטוחי >`,
		},
	},
	{
		title: `בד' תיק ביטוחי`,
		path: "https://polywizz.com/#/sn?g=b0735fcf-10c0-4482-a4bc-8da8e0eed2d2",
		svg: <Icon12 />,
	},
];
