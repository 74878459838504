import React from 'react'
import{ ReactComponent as  Icon_1 } from '../svg/footer/Icon_1.svg';
import{ ReactComponent as  Icon_2 } from '../svg/footer/Icon_2.svg';
import{ ReactComponent as  Icon_3 } from '../svg/footer/Icon_3.svg';


import '../css/footer.css';


export default function Footer(){

    const icons = [
        {   svg : <Icon_1 />,
            title : `יאיר אברהם שטרן 31 | באר שבע`
        },
        {   svg : <Icon_2 />,
            title : '072-211-9030/1'
        },
        {   svg : <Icon_3 />,
            title : `office@moreno-ins.co.il`
        },
];

return(
    <footer>

        <div className={`bg`}>
            <img src={require('../img/footer/bg.jpg')} />
        </div>
        <div className={`body`} >
            <div className={`title`}>
                מורנו סוכנות לביטוח (2005) בע"מ
            </div>
            <div className={`icons`}>
                {icons.map(icon=>{
                    return (
                        <div className={`icon`} >
                                {icon.svg}
                                <div className={`name`}>
                                {icon.title}
                                </div>
                        </div>
                    )
                })}
            
            </div>

            <div className={`links_con`} >
                    <a   target='_blank' className={`desk`} href={"https://www.facebook.com/morenoins/"} >
                        <img src={require(`../img/footer/f.png`)} />
                    </a>
                    <a   target='_blank' className={`mob`} href={" fb://page/154489921240529"} >
                        <img src={require(`../img/footer/f.png`)} />
                    </a>
                    <a   target='_blank'  className={`desk`} href="https://www.instagram.com/moreno__insurance/" >
                        <img src={require(`../img/footer/i.png`)} />
                    </a>
                    <a   target='_blank'  className={`mob`} href="instagram://user?username=moreno__insurance" >
                        <img src={require(`../img/footer/i.png`)} />
                    </a>
                    <a   target='_blank' className={`mob`} href={`tel:0722119030`} >
                        <img src={require(`../img/footer/t.png`)} />
                    </a>
                    <a  target='_blank'  href={`https://waze.com/ul/hsv89xxnmr`} >
                        <img src={require(`../img/footer/w.png`)} />
                    </a>
                    <a href={`https://wa.me/+972505771444`} target={`blank`}>
                        <img src={require(`../img/footer/wa.png`)} />
                    </a>
                    <a  target='_blank'  className={`desk`} href={`tel: 0722119030`} >
                        טלפון : 072-2119030
                    </a>
             </div>

             <div className={`halat`}>
                {`ט.ל.ח`}
             </div>

        </div>


    </footer>

);


}