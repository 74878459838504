import React from 'react';
import{ ReactComponent as  NavBg } from '../../svg/navBar/navbg-11.svg';
import{ ReactComponent as  Logo } from '../../svg/navBar/logo-11.svg';
import {Data} from '../../Data/Data.js';

import '../../css/nav.css';

export default function Nav(){
let lock = false;
const openMenuHandel = ()=>{
    
    const menuBth = document.querySelector(".menu-bth_burger");
    const humMenu = document.querySelectorAll(".homeNav");
        let time = 0.01;
        if(!menuBth.classList.contains("open")){
        lock = false; 

          menuBth.classList.add("open");
          humMenu.forEach((item , i)=>{
            setTimeout(()=>{
              item.classList.add("open");
              if( i == humMenu.length -1){
                  lock = true; 
              }
            }, time)
            time +=60; 
        })
        }else if(lock){
          menuBth.classList.remove("open");
          humMenu.forEach((item , i)=>{
              setTimeout(()=>{
                  item.classList.remove("open");
                }, time)
        }) 
            }
    } 
    
    return(
        <nav>

            <div className="menu-bth mob" onClick={openMenuHandel}>
                <span className="menu-bth_burger">
                </span>
            </div>

            <ul>
                <li className="logo">
                    <a href={`./`}> 
                        <Logo />
                    </a>
                </li>
                {Data.map(nav =>{
                    return (
                        <li class="homeNav">
                            <a href={nav.path}>
                                <NavBg />
                                {nav.title}
                            </a>
                            <div className={`mob icon`}>
                                {nav.svg}
                            </div>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )



}